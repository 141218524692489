import {useEffect, useState} from "react";
import Beep from "../media/BeepTransparent.svg";
import axios from "axios";
import {clear} from "localforage";

export default function BeepConnector({projectId, afterSuccess, output}) {

    const apiEndpoint = 'beep.api.worldz.tech/api/'

    const [linkCode, setLinkCode] = useState(null)
    const [accepted, setAccepted] = useState(false)

    useEffect(() => {
        axios.post(`https://${apiEndpoint}${projectId}/link/create/`)
            .then((resp) => {
                setLinkCode(resp.data.code)
                const checkInterval = setInterval(() => {
                    axios.get(`https://${apiEndpoint}${projectId}/link/check/${resp.data.code}`)
                        .then((r) => {
                            if (r.data.accepted) {
                                clearInterval(checkInterval)
                                output(r.data.receiver)
                                setAccepted(true)
                            }
                        })
                }, 1000)

                return () => clearInterval(checkInterval)
            })
    }, [])

    return (
        <div>
            <div className={'w-100 flex justify-between items-center'}>
                <img src={Beep} className={'w-2/3'}/>
                <div className={'text-start'}>
                    {
                        linkCode ? (
                            accepted ? (
                                <>
                                    <p><span className={'text-[#3cff21]'}><i className='bx bxs-check-circle'></i></span> Готово! Вы подписались на уведомления с этой платформы.</p>

                                    <p className={'underline pointer'} onClick={() => afterSuccess()}>Нажмите, чтобы продолжить</p>
                                </>

                            ) : (
                                <>
                                    <p>Ваш код подключения</p>
                                    <p className={'text-2xl'}>{linkCode}</p>
                                </>
                            )

                        ) : (
                            <>
                                <p>Запрашиваем код...</p>
                            </>
                        )
                    }
                </div>
            </div>
            {
                !accepted && (
                    <p><a className={'underline'} href={'https://t.me/beep_notify_bot'} target={'_blank'}>Перейдите в бота</a> и отправьте ему код подключения, а <b>затем вернитесь на эту страницу</b></p>
                )
            }
        </div>
    )
}