import PrimaryButton from "../../components/PrimaryButton";
import DangerBlock from "../../components/DangerBlock";
import InfoBlock from "../../components/InfoBlock";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import endpoints from "../../endpoints.json";

import beep from "../../media/BeepTransparent.svg";

export default function GetKeyPayments(props) {

    const [method, setMethod] = useState(null);
    const carfNumberInput = useRef(null);

    const sendPayInfo = () => {
        axios.post(endpoints.api + 'sendbeep/', {
            'key_id': props.keyId,
            'title': 'Как оплатить ключ',
            'body': `Чтобы оплатить ключ прямым переводом, переведите 249 рублей на карту.\n\n<b>Номер карты: 2200 7007 4213 6731</b>\n\n<b>ВАЖНО: в комментарии платежа укажите номер вашего ключа</b>\n\n<b>Номер вашего ключа: ${props.keyId}</b> `
        })
    }

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        if (props.promoActive) {
            props.moveStage()
        }
    }, [props.promoActive])

    return (
        <div>
            <h2 className={'text-2xl mb-5'}>Оплата</h2>

            {
                method === null && (
                    <>
                        <p>Выберите удобный способ оплаты</p><br/>

                        <div className={'flex flex-col gap-5'}>
                            <div
                                className={'flex items-center gap-5 p-5 border rounded-xl bg-none hover:bg-white hover:text-black transition-all cursor-pointer'}
                                onClick={() => {
                                    axios.post("https://vpn.back.worldz.tech/api/management/send_invoice/", {
                                        key_id: props.keyId,
                                    })
                                        .then((response) => {
                                            setMethod(("stars"))
                                        })
                                        .catch((error) => {
                                            alert('Ой! Ошибка... попробуйте еще раз или обратитесь в службу поддержки.')
                                        })
                                }}>
                                <i className='bx bxs-star'></i>
                                <div className={'text-start'}>
                                    <h3 className={'text-lg'}>Telegram Stars</h3>
                                    <p className={'text-sm'}>Оплата с помощью валюты Telegram Stars</p>
                                    <p className={'text-sm text-gray-300'}>Моментальная обработка платежа</p>
                                </div>
                            </div>
                            {/*<div*/}
                            {/*    className={'flex gap-5 items-center p-5 border rounded-xl bg-none hover:bg-white hover:text-black transition-all cursor-pointer'}*/}
                            {/*    onClick={() => setMethod(("bank"))}>*/}
                            {/*    <i className='bx bxs-credit-card-alt text-3xl'></i>*/}
                            {/*    <div className={'text-start'}>*/}
                            {/*        <h3 className={'text-lg'}>Онлайн через банк</h3>*/}
                            {/*        <p className={'text-sm'}>Привычный способ оплаты картой</p>*/}
                            {/*        <p className={'text-sm text-gray-300'}>Моментально</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </>
                )
            }

            {
                method === "card" && (
                    <>
                        <DangerBlock>В комментарии платежа обязательно укажите ваш номер ключа</DangerBlock>

                        <p>Номер вашего ключа</p>

                        <div className={'p-3 bg-slate-500 text-2xl rounded-xl mt-5 mb-5'}>
                            {props.keyId}
                        </div>

                        <p>Переведите 249 рублей или 3$ по номеру карты ниже.</p>

                        <div className={'p-3 bg-slate-500 text-2xl rounded-xl mt-5 mb-5'}>
                            2200 7007 4213 6731
                        </div>

                        <p>Прямые платежи на карту мы обрабатываем в течении часа. Переводите ровно ту сумму, которая
                            указана выше.</p>

                        <PrimaryButton onClick={sendPayInfo}>Отправить инструкцию по оплате в чат</PrimaryButton>

                        <PrimaryButton onClick={props.moveStage}>Я оплатил(-а)</PrimaryButton>

                    </>
                )
            }

            {
                method === 'bank' && (
                    <>
                        <InfoBlock>
                            <p>После оплаты с вашей карты будут ежемесячно списываться сумма подписки. Если вы передумаете
                                использовать Worldz VPN вы сможете отменить ежемесячные платежи.</p>
                        </InfoBlock>
                        <form id={'payment-form'} className={'text-start'} autoComplete={false}>
                            <label>Номер карты</label>
                            <input type={'text'} className={'p-3 bg-slate-500 text-2xl rounded-xl mt-1 mb-5 w-full'}
                                   data-cp={'cardNumber'}/>
                            <div className={'flex justify-between gap-20'}>
                                <div className={'flex gap-5'}>
                                    <div>
                                        <label>MM</label>
                                        <input type={'text'}
                                               className={'p-3 bg-slate-500 text-2xl rounded-xl mt-1 mb-5 w-full'}
                                               maxLength={2} data-cp={'expDateMonth'}/>
                                    </div>
                                    <div>
                                        <label>YY</label>
                                        <input type={'text'}
                                               className={'p-3 bg-slate-500 text-2xl rounded-xl mt-1 mb-5 w-full'}
                                               maxLength={2} data-cp={'expDateYear'}/>
                                    </div>

                                </div>
                                <div>
                                    <label>CVV</label>
                                    <input type={'text'}
                                           className={'p-3 bg-slate-500 text-2xl rounded-xl mt-1 mb-5 w-full'}
                                           maxLength={3} data-cp={'cvv'}/>
                                </div>
                            </div>
                            <label>Имя держателя карты</label>
                            <input type={'text'}
                                   className={'p-3 bg-slate-500 text-2xl rounded-xl mt-1 mb-5 w-full'} maxLength={3}
                                   data-cp={'name'}/>
                        </form>
                    </>
                )
            }

            {
                method === 'stars' && (
                    <>
                        <img src={beep} className={'h-[200px]'}/>

                        <h2>Оплата с помощью Beep</h2>

                        <p>Мы отправили вам счет в бота. Загляните в чат. После оплаты вы получите 30 дней подписки.</p>

                        <p>Вы можете оплатить этот счет в любой момент.</p>

                        <PrimaryButton onClick={props.moveStage}>Хорошо</PrimaryButton>
                    </>
                )
            }

        </div>
    )
}