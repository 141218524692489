import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../endpoints.json'
import DangerBlock from "../../components/DangerBlock";

export default function GetKeyGeneration(props) {

    const [err, setErr] = useState(false)

    useEffect(() => {
        axios.get(endpoints.api + `createkey/`)
            .then((resp) => {
                console.log(resp.data)
                if (resp.data.ok) {
                    props.setKeyData(resp.data)
                    props.moveStage()
                } else {
                    setErr(true)
                }
            })
            .catch((err) => {
                setErr(true)
            })
    }, []);

    return (
        <div>
            {
                err ? (
                    <>
                        <h2 className={'text-2xl mb-5'}>Возникла ошибка</h2>

                        <DangerBlock>
                            К сожалению, мы не можем создать для вас ключ. Убедитесь, что вы не находитесь в черном списке или попробуйте позже.
                        </DangerBlock>
                    </>
                ) : (
                    <>
                        <h2 className={'text-2xl mb-5'}>Создаем ваш ключ</h2>

                        <p className={'text-7xl'}><i className='bx bxs-key bx-tada'></i></p>
                    </>
                )
            }

        </div>
    )
}