import {useEffect, useState} from "react";
import axios from "axios";
import endpoints from '../../endpoints.json'
import DangerBlock from "../../components/DangerBlock";
import InputField from "../../components/InputField";
import PrimaryButton from "../../components/PrimaryButton";

export default function GetKeyPromo(props) {

    const [err, setErr] = useState(false)
    const [promo, setPromo] = useState("")
    const [stage, setStage] = useState("promo-input")
    const [successLabel, setSuccessLabel] = useState("")
    const [errorLabel, setErrorLabel] = useState("")
    const [withOutPromo ,setWithOutPromo] = useState(false)

    const activatePromo = () => {
        setWithOutPromo(false)
        setStage('loading')
        axios.post(endpoints.api + "activate_promotion_code/", {
            'code': promo,
            'key_id': props.keyData.keyId,
        })
            .then((response) => {
                if (response.data.ok) {
                    setSuccessLabel("Отлично! Промокод активирован! Оплачивать пока ничего не нужно.")
                    setStage("done")
                } else {
                    setErrorLabel(response.data.error)
                    setStage('promo-input')
                }
            })
            .catch((error) => {
                console.log(error.response)
                setErrorLabel(error.response.data.error)
                setStage('promo-input')
            })

    }

    return (
        <div>
            {
                err ? (
                    <>
                        <h2 className={'text-2xl mb-5'}>Возникла ошибка</h2>

                        <DangerBlock>
                            К сожалению, мы не можем создать для вас ключ. Убедитесь, что вы не находитесь в черном
                            списке или попробуйте позже.
                        </DangerBlock>
                    </>
                ) : (
                    <>
                        <h2 className={'text-2xl mb-5'}>Промокод</h2>

                        {
                            stage === "promo-input" && (
                                <>
                                    <div className={'flex justify-center gap-1 items-center'}>
                                        <InputField setSourceData={setPromo} placeholder={'Введите промокод'}/>
                                        <PrimaryButton onClick={activatePromo}><i
                                            className='bx bx-right-arrow-alt'></i></PrimaryButton>
                                    </div>

                                    <p className={'text-red-500'}>
                                        {errorLabel}
                                    </p>

                                    <div id={'no-promo-label'}>
                                        <p className={'cursor-pointer underline text-slate-500'}
                                           onClick={() => {
                                               document.querySelector('#no-promo-label').innerHTML = "<p>Ничего страшного! Держите промокод <b>GIFT5</b> на 5 дней подписки. Круто же?</p>"
                                               setWithOutPromo(true)
                                           }}>У меня нет промокода</p>
                                    </div>

                                    {
                                        withOutPromo && (
                                            <p className={'underline'} onClick={props.moveStage}>Все равно продолжить без промокода</p>
                                        )
                                    }

                                </>
                            )
                        }

                        {
                            stage === 'loading' && (
                                <>
                                    <i className='bx bx-loader-alt bx-spin text-6xl'></i>
                                </>
                            )
                        }

                        {
                            stage === "done" && (
                                <>
                                    <p className={'text-green-500'}>{successLabel}</p>

                                    <PrimaryButton onClick={() => {
                                        props.setPromoActivated(true)
                                        props.moveStage()
                                    }
                                    }>Дальше</PrimaryButton>
                                </>
                            )
                        }
                    </>
                )
            }

        </div>
    )
}