import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Getkey from "./pages/GetKey";
import Ambassador from "./pages/Ambassador";
import KeyDetailsLogin from "./pages/KeyDetailsLogin";
import ConnectPage from "./pages/connect";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "key/",
        element: <Getkey/>
    },
    {
        path: "ambassador/",
        element: <Ambassador/>
    },
    {
        path: "personal/",
        element: <KeyDetailsLogin/>
    },
    {
        path: "connect/:keyId",
        element: <ConnectPage />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
