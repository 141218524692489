import PrimaryButton from "../components/PrimaryButton";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import InfoBlock from "../components/InfoBlock";

export default function ConnectPage() {

    const {keyId} = useParams();

    useEffect(() => {
        window.location = `openvpn://import-profile/https://vpn.back.worldz.tech/storage/keys/${keyId}.ovpn`;
    }, []);

    return (
        <div>
            <div
                className={'container p-5 m-auto min-h-screen min-v-screen flex flex-col justify-center items-center text-center'}>
                <div className={'flex flex-col gap-5'}>
                    <h2 className={'text-xl'}>Cейчас начнется процесс подключения</h2>
                    <p>Номер ключа: {keyId}</p>

                    <InfoBlock>
                        <p>Если вы видите ошибку "Адрес недействительный", это означает, что вы не установили приложение OpenVPN Connect. Выберите ниже свою систему, чтобы начать загрузку. После установки снова вернитесь на эту страницу.</p>

                        <div className={'flex flex-col gap-5 text-center mt-5'}>
                            <a href={'https://openvpn.net/downloads/openvpn-connect-v3-windows.msi'}><b>Windows</b></a>
                            <a href={'https://openvpn.net/downloads/openvpn-connect-v3-macos.dmg'}><b>MacOS</b></a>
                            <a href={'https://openvpn.net/openvpn-client-for-linux/'}><b>Linux</b></a>
                            <a href={'https://play.google.com/store/apps/details?id=net.openvpn.openvpn'}><b>Android</b></a>
                            <a href={'https://itunes.apple.com/us/app/openvpn-connect/id590379981?mt=8'}><b>iOS</b></a>
                            <a href={'https://play.google.com/store/apps/details?id=net.openvpn.openvpn'}><b>ChromeOS</b></a>
                        </div>

                    </InfoBlock>
                </div>
            </div>
        </div>
    )
}