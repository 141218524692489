import logo from '../logo.svg';
import '../App.css';
import InfoBlock from "../components/InfoBlock";
import PrimaryButton from "../components/PrimaryButton";
import GetKeyWelcome from "./GetKeySubpages/GetKeyWelcome";
import {useState} from "react";
import GetKeyBeep from "./GetKeySubpages/GetKeyBeep";
import GetKeyGeneration from "./GetKeySubpages/GetKeyGeneration";
import GetKeyDone from "./GetKeySubpages/GetKeyDone";
import GetKeyTariffs from "./GetKeySubpages/GetKeyTariffs";
import GetKeyPayments from "./GetKeySubpages/GetKeyPayment";
import GetKeyPromo from "./GetKeySubpages/GetKeyPromo";


function GetKey() {

    const [tariff, setTariff] = useState(0)

    const [email, setEmail] = useState('')

    const [keyData, setKeyData] = useState({})
    const [promoActive, setPromoActive] = useState(false)

    const stages = [
        ["Старт", <GetKeyWelcome/>, true],
        ["Ключ",
            <GetKeyGeneration email={email} moveStage={() => setStage(stage + 1)} setKeyData={setKeyData}/>, false],
        ["Beep", <GetKeyBeep email={email} setEmail={setEmail} moveStage={() => setStage(stage + 1)}
                             keyData={keyData}/>, false],
        ["Промо", <GetKeyPromo moveStage={() => setStage(stage + 1)}
                             keyData={keyData} setPromoActivated={setPromoActive}/>, false],
        ["Оплата", <GetKeyPayments promoActive={promoActive} tariff={tariff} setTariff={setTariff} keyId={keyData.keyId} moveStage={() => setStage(stage + 1)}/>, false],
        ["Готово", <GetKeyDone tariff={tariff} keyData={keyData}/>, true],
    ]


    const [stage, setStage] = useState(0)

    return (
        <div>
            <div
                className={'container p-5 m-auto min-h-screen min-v-screen flex flex-col justify-center items-center text-center'}>
                <div className={'flex gap-5 m-10'}>
                    {
                        stages.map((s, index) =>
                            <div>

                                {
                                    index < stage && (
                                        <span className={'text-green-500'}>
                                    <i className='bx bxs-check-circle'></i>
                                        </span>
                                    )
                                }
                                {
                                    index > stage && (
                                        <span className={'text-[#19242c]'}>
                                    <i className='bx bx-circle'></i>
                                    </span>
                                    )

                                }
                                {
                                    index == stage && (
                                        <span className={'text-white'}>
                                    <i className='bx bx-circle'></i>
                                    </span>
                                    )
                                }

                                <p className={(index !== stage ? 'text-[#19242c]' : 'text-white')}>{s[0]}</p>
                            </div>
                        )
                    }
                </div>
                <div className={'bg-[#19242c] p-10 rounded-2xl w-1/3 max-lg:w-full'}>
                    {stages[stage][1]}
                </div>

                {
                    stage !== stages.length - 1 && stages[stage][2] && (
                        <PrimaryButton onClick={() => setStage(stage + 1)}>Дальше <i
                            className='bx bx-right-arrow-alt'></i></PrimaryButton>
                    )

                }

            </div>
        </div>
    );
}

export default GetKey;
