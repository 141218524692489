import {useState} from "react";

export default function ChecklistPoint({checkpoint, label, pointsHolder, pointsHolderSet}) {

    const [checked, setChecked] = useState(false)

    const pressed = () => {
        if (checked) pointsHolderSet(pointsHolder - 1)
        else pointsHolderSet(pointsHolder + 1)
        setChecked(!checked)
    }

    return (
        <div onClick={pressed} className={'p-5 ' + (checked ? 'bg-[#7ce072]' : 'bg-[#11191f]') + ' mt-10 rounded-xl cursor-pointer'}>
            <p>{label}</p>
        </div>
    )
}