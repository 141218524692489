import InputField from "../components/InputField";
import {useState} from "react";
import axios from "axios";
import PrimaryButton from "../components/PrimaryButton";
import {sha256} from "js-sha256";
import KeySettingsModal from "../components/management/KeySettingsModal";


export default function KeyDetailsLogin() {

    const [keyId, setKeyId] = useState("")
    const [verificationCode, setVerificationCode] = useState("")
    const [beepCode, setBeepCode] = useState("")

    const [step, setStep] = useState(1)

    const [userKeysData, setUserKeysData] = useState([])

    const [selectedKey, setSelectedKey] = useState("")
    const [selectedKeyData, setSelectedKeyData] = useState(null)

    const [keyDetailsOpenned, setKeyDetailsOpenned] = useState(false)

    async function digestMessage(message) {
        const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", msgUint8); // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join(""); // convert bytes to hex string
        return hashHex;
    }

    const SendVerificationCode = () => {
        axios.get('https://vpn.back.worldz.tech/api/getbeep?keyId=' + keyId)
            .then((resp) => {
                let beepCode = resp.data['beep']
                setBeepCode(beepCode)
                axios.post(`https://beep.api.worldz.tech/api/5bf3a3b8-040d-4d32-96af-7dd57c456889/${beepCode}/send_verification/`)
                    .then((resp) => {
                        setStep(2)
                    })
            })
    }

    const VerificateLogin = () => {
        digestMessage(verificationCode)
            .then((hash) => {
                axios.post(`https://beep.api.worldz.tech/api/5bf3a3b8-040d-4d32-96af-7dd57c456889/${beepCode}/verificate/`, {
                    "value": hash
                })
                    .then((resp) => {
                        axios.get("https://vpn.back.worldz.tech/api/keys/?beep_code=" + beepCode)
                            .then((resp) => {
                                setUserKeysData(resp.data)
                                setStep(3)
                            })

                    })
            })
    }

    return (
        <div>
            <div
                className={'container m-auto min-h-screen min-v-screen flex flex-col justify-center items-center text-center p-5'}>
                {
                    step === 1 && (
                        <div className={'bg-[#19242c] p-10 rounded-2xl w-1/3 max-lg:w-full'}>
                            <h1 className={'text-3xl'}>Личный кабинет</h1>
                            <InputField setSourceData={setKeyId} placeholder={"Номер вашего ключа"}/><br/>
                            <PrimaryButton onClick={SendVerificationCode}>Войти</PrimaryButton>
                        </div>
                    )
                }

                {
                    step === 2 && (
                        <div className={'bg-[#19242c] p-10 rounded-2xl w-1/3 max-lg:w-full'}>
                            <h1 className={'text-3xl'}>Личный кабинет</h1>
                            <p>Мы отправили вам код подтверждения через Beep. Пожалуйста, введите его ниже.</p>
                            <InputField setSourceData={setVerificationCode} placeholder={"Код подтверждения"}/><br/>
                            <PrimaryButton onClick={VerificateLogin}>Войти</PrimaryButton>
                        </div>
                    )
                }

                {
                    step === 3 && (
                        <div className={'w-full text-start flex flex-col gap-5'}>
                            {/*INFO MODAL*/}
                            {
                                selectedKeyData && (
                                    <KeySettingsModal keyDetails={selectedKeyData} setKeyDetails={setSelectedKeyData}/>
                                )
                            }

                            <div>
                                <h2 className={'text-5xl'}>Личный кабинет</h2>
                                <p>Код в системе: {beepCode}</p>
                            </div>
                            <div className={'flex gap-5 justify-between items-start max-lg:flex-col'}>
                                <div className={'w-1/2 max-lg:w-full'}>
                                    <h2 className={'text-3xl'}>Ваши ключи</h2>

                                    <div className={'flex flex-col gap-3'}>
                                        {
                                            userKeysData.map((key, index) =>
                                                <div
                                                    className={'p-3 rounded-xl flex justify-between items-center cursor-pointer hover:bg-slate-900 hover:text-white transition-all ' + (selectedKey === key.keyId ? 'bg-white text-black' : 'bg-slate-700 text-white')}
                                                    onClick={() => {
                                                        setSelectedKey(key.keyId)
                                                        setSelectedKeyData(userKeysData[index])
                                                        console.log(userKeysData[index])
                                                        console.log(selectedKeyData)
                                                        console.log(userKeysData)
                                                        console.log(index)
                                                    }} key={key.keyId}>
                                                    <div>
                                                        <p className={'text-sm'}>Ключ</p>
                                                        <p className={'text-xl font-bold'}><b>{key.keyId}</b></p>
                                                    </div>

                                                    <div className={'text-end'}>
                                                        <p>Дней осталось: {key.subscription_days}</p>
                                                        {
                                                            (key.subscription_days > 0 && key.subscription_days < 4) && (
                                                                <p className={'text-yellow-500'}>Подписка заканчивается</p>
                                                            )
                                                        }

                                                        {
                                                            (key.subscription_days >= 4) && (
                                                                <p className={'text-green-500'}>Подписка активна</p>
                                                            )
                                                        }

                                                        {
                                                            (key.subscription_days === 0) && (
                                                                <p className={'text-red-500'}>Подписка окончена</p>
                                                            )
                                                        }
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>
                    )
                }

            </div>
        </div>
    )
}