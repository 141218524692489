import logo from '../logo.svg';
import '../App.css';
import InfoBlock from "../components/InfoBlock";
import PrimaryButton from "../components/PrimaryButton";
import {Link} from "react-router-dom";
import FeatureBlock from "../components/FeatureBlock";
import DocFile from "../components/DocFile";
import ChecklistPoint from "../components/checklistPoint";
import {useState} from "react";
import axios from "axios";
import endpoints from "../endpoints.json";

function Ambassador() {
    const [checklistPoints, setChecklistPoints] = useState(0)
    const [ambTgName, setAmbTgName] = useState('')

    const [levelCalc, setLevelCalc] = useState(10)
    const [postsCalc, setPostsCalc] = useState(21)

    const [enrolled, setEnrolled] = useState(false)
    // 7185430999

    const sendTgName = () => {
        const btn = document.querySelector("#send-btn");
        btn.innerHTML = "<i class=\'bx bx-loader-circle bx-spin\' ></i>"

        axios.post(endpoints.api + 'directbeep/', {
            'beepcode': '7185430999',
            'title': 'Заявка на участие в программе Амбассадоров',
            'body': 'TG: ' + ambTgName
        })
            .then((resp) => {
                setEnrolled(true)
                btn.innerHTML = "Send"
            })
    }

    return (
        <div>
            <div
                id={'start'}
                className={'container p-5 m-auto flex flex-col gap-10 items-center justify-center min-h-screen rounded-xl text-center'}>
                <div className={'p-20 max-lg:p-10 shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] rounded-3xl'}>
                    <div className={'text-start'}>
                        <h2 className={'text-4xl max-lg:text-lg'}>Добро пожаловать, наш будущий<br/><span
                            className={'font-bold bg-gradient-to-br from-sky-400 to-fuchsia-500 inline-block text-transparent bg-clip-text text-9xl max-lg:text-5xl text-shadow'}>AMBASSADOR</span>
                        </h2>
                    </div>

                    <p className={'text-2xl max-lg:text-lg'}>Звучит гордо. А ведь так оно и есть.</p>
                    <br/>
                    <p>Присоединяйтесь к программе Ambassador и зарабатывайте деньги, рекламируя Worldz VPN.<br/>
                        Читайте ниже о том, как это работает и как вступить в программу. Все просто.</p>
                </div>

            </div>

            <div className={'container p-5 m-auto mt-20 '}>
                <h2 className={'text-5xl'}>Подсчитайте свой доход</h2>
                <div className={'bg-[#19242c] p-20 w-1/2 max-lg:w-full m-auto mt-20 rounded-xl'}>
                    <div>
                        <p className={'text-2xl'}>Уровень: {levelCalc}</p>
                        <input type={'range'} min={1} max={10} defaultValue={10} className={'w-full'}
                               onChange={(e) => setLevelCalc(e.target.value)}/>
                    </div>

                    <div>
                        <p className={'text-2xl'}>Постов в неделю: {postsCalc}</p>
                        <input type={'range'} min={1} max={21} defaultValue={21} className={'w-full'}
                               onChange={(e) => setPostsCalc(e.target.value)}/>
                    </div>

                    <div className={'flex justify-between max-lg:flex-col gap-5'}>
                        <div className={'bg-[#11191f] p-5 mt-5 rounded-xl'}>
                            <p className={'text-2xl'}>~{Math.ceil(Math.ceil(postsCalc * 1.2) * 4)}</p>
                            <p>пользователей в месяц</p>
                        </div>

                        <div className={'bg-[#11191f] p-5 mt-5 rounded-xl'}>
                            <p className={'text-2xl'}>~{Math.ceil(Math.ceil(postsCalc * 1.2) * 4 * 299 * (0.7 + 0.03 * levelCalc))} RUB</p>
                            <p>доход в месяц</p>
                        </div>

                        <div className={'bg-[#11191f] p-5 mt-5 rounded-xl'}>
                            <p className={'text-2xl'}>~{Math.ceil(Math.ceil(Math.ceil(postsCalc * 1.2) * 4 * 299 * (0.7 + 0.03 * levelCalc)) / 80)} USD</p>
                            <p>доход в месяц</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className={'container p-5 m-auto mt-20'}>
                <h2 className={'text-5xl'}>How it works</h2>
                <div className={'flex max-lg:flex-col gap-10 justify-around items-start mt-5'}>
                    <div className={'p-10 bg-[#19242c] rounded-xl text-center flex-1 max-lg:w-full'}>
                        <p><b>1. Вступите в программу</b></p>
                        <p>Зарегистрируйтесь как Амбассадор</p>
                    </div>
                    <div className={'p-10 bg-[#19242c] rounded-xl text-center flex-1 max-lg:w-full'}>
                        <p><b>2. Получите ваш код Амбассадора</b></p>
                        <p>После регистрации вы получите ваш личный код</p>
                    </div>
                    <div className={'p-10 bg-[#19242c] rounded-xl text-center flex-1 max-lg:w-full'}>
                        <p><b>3. Поделитесь своим кодом</b></p>
                        <p>Расскажите другим людям о Worldz VPN и поделитесь с ними своим личным кодом. Пользователи
                            получат 10 дней бесплатного доступа к VPN, а вы получите свое вознаграждение</p>
                    </div>
                    <div className={'p-10 bg-[#19242c] rounded-xl text-center flex-1 max-lg:w-full'}>
                        <p><b>4. Получите вознаграждение</b></p>
                        <p>После того, как пользователь оплатит ключ с помощью вашего промо-кода, вы получите
                            вознаграждение: первый месяц - 90% от суммы платежа, а второй - 50%, а дальше все зависит от
                            вас</p>
                    </div>
                </div>
            </div>

            <div className={'container m-auto mt-20 p-5'}>
                <h2 className={'text-5xl'}>Как вступить в программу</h2>
                <p className={'text-xl'}>Проверьте подходите ли вы нам, отметьте пункты ниже.</p>

                <div
                    className={'text-center p-20 bg-[#19242c] mb-20 shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] w-1/2 max-lg:w-full m-auto mt-20 rounded-xl'}>
                    <h3 className={'text-3xl'}>Чеклист идеального Амбассадора</h3>

                    <ChecklistPoint checkpoint={'age'} label={'Я старше 16 лет'} pointsHolder={checklistPoints}
                                    pointsHolderSet={setChecklistPoints}/>
                    <ChecklistPoint checkpoint={'age'} label={'У меня есть банковский счет'} pointsHolder={checklistPoints}
                                    pointsHolderSet={setChecklistPoints}/>
                    <ChecklistPoint checkpoint={'age'} label={'У меня есть 40 или больше минут свободного времени в день'}
                                    pointsHolder={checklistPoints} pointsHolderSet={setChecklistPoints}/>

                    {
                        checklistPoints === 3 && (
                            enrolled ? (
                                <div className={'mt-20'}>
                                    <p className={'text-2xl'}>Готово!</p>
                                    <p>Скоро мы с вами свяжемся</p>
                                </div>
                            ) : (
                                <div className={'mt-20'}>
                                    <p className={'text-2xl'}>Вы идеальный Амбассадор!</p>
                                    <p>Давайте свяжемся</p>

                                    <input onInput={(e) => setAmbTgName(e.target.value)} type={'text'}
                                           className={'bg-[#11191f] p-5 mt-5 rounded-xl me-5'}
                                           placeholder={'Ваш Telegram'}/>
                                    <PrimaryButton onClick={sendTgName} id={'send-btn'}>Отправить</PrimaryButton>
                                </div>
                            )

                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Ambassador;
