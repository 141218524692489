export default function DangerBlock(props) {
    return (
        <div className={'p-5 bg-red-400 text-start rounded-2xl mt-5 mb-5 flex gap-5 items-center'}>
            <div className={'text-5xl'}>
                <i className='bx bxs-error-alt'></i>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}