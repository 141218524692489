import logo from '../logo.svg';
import '../App.css';
import InfoBlock from "../components/InfoBlock";
import PrimaryButton from "../components/PrimaryButton";
import {Link} from "react-router-dom";
import FeatureBlock from "../components/FeatureBlock";
import DocFile from "../components/DocFile";
import {useEffect} from "react";

function App() {
    return (
        <div>
            <div
                className={'h-screen v-screen flex justify-center flex-col items-center text-center bg-gradient-to-br from-sky-400 to-fuchsia-500 rounded-b-full shadow-[0px_0px_20px_15px_#ed64a6]'}>
                <div className={'container m-auto'}>
                    <div className={'w-auto m-auto'}>
                        <div>
                            <span className={'text-xl'}>бескомпромиссный</span>
                        </div>

                        <h1 className={'text-9xl max-lg:text-7xl'}><span className={'-ms-20'}>Worldz</span> <br/><span
                            className={'ms-20 font-bold'}>VPN</span></h1>

                        <p>защищено и безопасно</p>
                    </div>

                    {/*<InfoBlock>*/}
                    {/*    <p><b>Бесплатно или платно - выбирать вам</b></p>*/}
                    {/*    <p>Вы можете получить ключ бесплатно. Такой ключ будет иметь ограничение на трафик - 300 МБ в месяц. При желании вы можете увеличить лимит, но за дополнительную плату.</p>*/}
                    {/*</InfoBlock>*/}

                    <Link to={'key/'}><PrimaryButton><i className='bx bxs-key'></i> Получить ключ</PrimaryButton></Link>
                </div>

            </div>

            <div className={'container m-auto flex max-lg:flex-col gap-10 justify-between mt-20 items-center p-5'}>
                <div className={'w-1/2 max-lg:w-full'}>
                    <h2 className={'text-9xl'}>Почему Worldz VPN?</h2>
                </div>
                <div className={'w-1/2 max-lg:w-full'}>
                    <div className={'flex flex-col gap-5 mt-5'}>
                        <FeatureBlock>
                            <h3 className={'text-xl'}><i className='bx bx-globe'></i> Высокоскоростные сервера</h3>
                            <p className={'text-lg'}>Наши серверы достаточно мощны, чтобы справляться с большим потоком
                                пользователей - мы не будем замедляться со временем, как другие VPN.</p>
                        </FeatureBlock>

                        <FeatureBlock>
                            <h3 className={'text-xl'}><i className='bx bxs-lock'></i> Безопасно</h3>
                            <p className={'text-lg'}>Использование Worldz VPN абсолютно безопасно - мы не храним
                                абсолютно никакой информации о вас. А также мы не отслеживаем ваши запросы.</p>
                        </FeatureBlock>

                        <FeatureBlock>
                            <h3 className={'text-xl'}><i className='bx bxs-coin'></i> Дешево</h3>
                            <p className={'text-lg'}>У нас один из самых дешевых VPN-сервисов в мире - всего 3 доллара
                                или 249 рублей за месяц безлимитного тарифа.</p>
                        </FeatureBlock>
                    </div>

                </div>
            </div>

            <div className={'container p-5 m-auto flex max-lg:flex-col gap-10 justify-between mt-20 items-center'}>
                <div className={'w-1/2 max-lg:w-full'}>
                    <h2 className={'text-9xl'}>Сколько?</h2>
                </div>
                <div className={'w-1/2 max-lg:w-full'}>
                    <div className={'flex max-lg:flex-col justify-around max-lg:justify-between gap-10'}>
                        <div className={'p-20 bg-[#19242c] text-center rounded-2xl'}>
                            <h3 className={'text-2xl font-bold'}>Ограниченный</h3>
                            <p><b>Бесплатно</b></p>
                            <p className={'mt-2'}>80 ГБ в месяц</p>
                        </div>
                        <div className={'p-20 bg-[#19242c] text-center rounded-2xl shadow-[0px_0px_20px_20px_#805ad5]'}>
                            <h3 className={'text-2xl font-bold'}><span className={'text-[#FF53EE]'}>Без</span>граничный
                            </h3>
                            <p><b>3 USD / 249 RUB</b></p>
                            <p className={'flex items-center gap-1'}><i className='bx bx-infinite text-3xl'></i> ГБ в месяц</p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id={'start'}
                className={'container p-5 m-auto flex flex-col gap-10 items-center justify-center h-screen max-lg:h-full max-lg:mt-20 rounded-xl text-center'}>
                <div className={'p-20 max-lg:p-10 shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] rounded-3xl'}>
                    <div className={'text-start'}>
                        <h2 className={'text-4xl max-lg:text-lg'}>Присоединяйтесь к программе<br/><span
                            className={'font-bold bg-gradient-to-br from-sky-400 to-fuchsia-500 inline-block text-transparent bg-clip-text text-9xl max-lg:text-5xl text-shadow'}>AMBASSADOR</span>
                        </h2>
                    </div>

                    <p className={'text-4xl max-lg:text-lg text-end'}>и зарабатывайте</p>
                    <br/>
                    <a href={'ambassador/'}><PrimaryButton>Узнать больше</PrimaryButton></a>
                </div>

            </div>

            <div className={'container m-auto h-screen flex justify-center flex-col items-center text-center'}>
                <div>
                    <h2 className={'text-9xl max-lg:text-7xl'}>Время получить свой ключ к <span
                        className={'text-[#FF53EE]'}>свободе</span></h2>
                    <Link to={'key/'}><PrimaryButton><i className='bx bxs-key'></i> Получить ключ</PrimaryButton></Link>
                </div>
            </div>

        </div>
    );
}

export default App;
