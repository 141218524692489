import PrimaryButton from "../../components/PrimaryButton";
import DangerBlock from "../../components/DangerBlock";
import {useEffect, useState} from "react";
import InfoBlock from "../../components/InfoBlock";
import axios from "axios";
import endpoints from '../../endpoints.json'

export default function GetKeyDone(props) {

    const [paid, setPaid] = useState(true)
    const [alertRead, setAlertRead] = useState(false)
    const [pressedPaid, setPressedPaid] = useState(false)

    const [infoEmailSending, setInfoEmailSending] = useState(false)

    const sendInfoEmail = () => {
        const email = document.querySelector('#infoEmail').value
        const emailField = document.querySelector('#infoEmail')
        const btn = document.querySelector('#infoEmailBtn')
        const statusLabel = document.querySelector('#infoEmailStatus')

        btn.classList.add('hidden')
        setInfoEmailSending(true)
        emailField.classList.add('hidden')
        statusLabel.innerHTML = "<i class='bx bxs-envelope bx-flashing' ></i> Отправляем данные"
        statusLabel.classList.remove('hidden')

        axios.get(endpoints.api + `keyinfo/email?email=${email}&keyId=${props.keyData.keyId}`)
            .then((resp) => {
                if (resp.data.ok) {
                    statusLabel.innerHTML = "<i class='bx bx-check' ></i> Данные успешно отправлены"
                } else {
                    statusLabel.innerHTML = "<i class='bx bxs-error' ></i> Не удалось отправить данные"
                }
            })
            .catch((err) => {
                statusLabel.innerHTML = "<i class='bx bxs-error' ></i> Не удалось отправить данные из-за внутренней ошибки"
            })

        statusLabel.innerHTML = "<i class='bx bxs-envelope bx-flashing' ></i> Отправляем данные"
        btn.classList.remove('hidden')
        emailField.classList.remove('hidden')
        setInfoEmailSending(false)
    }

    useEffect(() => {
        axios.post(endpoints.api + 'sendbeep/', {
            'key_id': props.keyData.keyId,
            'title': 'Ваш ключ создан!',
            'body': `Здравствуйте! Ваш ключ создан и активирован.\n\nДля подключения к VPN необходимо приложение OpenVPN Connect. Вы сможете его скачать при переходе по ссылке подключения.\n\n<b>Ссылка для подключения:</b> ${props.keyData.keyAccess}\n\nУстановите приложение Worldz VPN Personal для быстрого управления своими ключами: https://personal.vpn.worldz.tech/`
        })
            .then((resp) => {

            })
    }, []);

    const SendKeyInfo = () => {
        const statusLabel = document.querySelector('#infoEmailStatus')

        axios.post(endpoints.api + 'sendbeep/', {
            'key_id': props.keyData.keyId,
            'title': 'Информация о вашем ключе',
            'body': `Здравствуйте! Вы запросили информацию о ключе. Она находится ниже:\n\n<b>Номер ключа:</b> ${props.keyData.keyId}\n\n <b>Ссылка для подключения:</b> ${props.keyData.keyAccess}`
        })
            .then((resp) => {
                statusLabel.innerHTML = "<i class='bx bx-check' ></i> Данные успешно отправлены"

            })
    }

    return (
        <div>
            {
                props.tariff === 0 || paid ? (
                        <>
                            <h2 className={'text-2xl mb-5'}>Готово!</h2>
                            <p>Поздравляем! Ваш ключ создан. Осталось лишь подключить его.</p>
                            <br/>

                            <div className={'mb-5'}>
                                <p>Номер вашего ключа</p>
                                <div className={'p-3 bg-slate-500 text-2xl rounded-xl'}>
                                    {props.keyData.keyId}
                                </div>
                            </div>

                            <p>Если вы хотите подключить текущее устройство, просто нажмите на кнопку ниже и следуйте простым инструкциям.</p>

                            <a target={'_blank'}
                               href={`${props.keyData.keyAccess}`}>
                                <PrimaryButton><i
                                    class='bx bx-link'></i> Подключить текущее устройство</PrimaryButton></a>

                            <p>Если вы хотите подключить другое устройство, то просто скопируйте ссылку ниже и перейдите по ней на том устройстве, которое хотите подключить.</p>

                            <div
                                className={'p-3 w-full break-words bg-slate-700 mt-5 mb-5 text-neutral-300 text-start rounded-xl'}>
                                <p>{props.keyData.keyAccess}</p>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <h2 className={'text-2xl mb-5'}>Оплата</h2>

                            <p>Beep will now send you a payment link. Please note that payment is made in cryptocurrency
                                - it's easy, just follow the instructions.</p><br/>

                            <p>The paid rate will take effect as soon as you make the payment.</p>

                            <PrimaryButton onClick={() => setPaid(true)}>Next</PrimaryButton>
                        </>
                    )
            }


        </div>
    )
}