import PrimaryButton from "../PrimaryButton";

export default function KeySettingsModal({keyDetails, setKeyDetails}) {

    const removeKey = () => {

    }

    return (
        <div className={'fixed bg-black w-screen m-0 start-0 bottom-0 p-10 rounded-t-3xl'}>
            <span className={'absolute top-0 end-0 m-10'} onClick={() => {
                setKeyDetails(null)
            }}>X</span>
            <h3 className={'text-4xl'}>Настройки ключа</h3>
            <div className={'flex justify-between items-center'}>
                <p>{keyDetails.keyId}</p>
                <p className={'text-end'}>

                    {
                        (keyDetails.subscription_days > 0 && keyDetails.subscription_days < 4) && (
                            <p className={'text-yellow-500'}>Подписка заканчивается</p>
                        )
                    }

                    {
                        (keyDetails.subscription_days >= 4) && (
                            <p className={'text-green-500'}>Подписка активна</p>
                        )
                    }

                    {
                        (keyDetails.subscription_days === 0) && (
                            <p className={'text-red-500'}>Подписка окончена</p>
                        )
                    }
                </p>
            </div>

            <p className={'text-slate-500'}>Ярлык не установлен. <u>Установить?</u></p>

            <div className={'flex gap-5'}>
                <PrimaryButton><span className={'text-red-500'}>Удалить ключ</span></PrimaryButton>
                <PrimaryButton>Оплатить подписку</PrimaryButton>
            </div>
        </div>
    )
}